.bordericon1 {
  border: 2px dashed;
  padding: 28px;
  border-radius: 0 0 25px 0;
  font-size: 25px;
}

.iconstyle {
  border-radius: 25px;
  border: 1 solid black !important;
  padding: 15px;
  color: white !important;
  background-color: black !important;
  font-size: 20px;
}

// email css
$primary: rgb(32, 32, 32);
$secondary:#00B3F2;
$white: #565656;
$gray: #969696;

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__label2 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(152, 152, 152);
  margin-bottom: -8px;
}

.form__field1 {
  width: 100%;
  height: 12px;
  border: 0;
  font-size: 1rem;
  padding-top: 7px;
  font-weight: 500;
  opacity: 0.4;
}

.form__field2 {
  width: 65%;
  border: 0;
  font-size: 1rem;
  border-bottom: 2px solid $gray;
  color: $white;
  padding: 7px 0;
  background: transparent;
  font-weight: 600;
  opacity: 0.4;
  outline: none !important;
}

.messagefield {
  width: 58%;
  border: 0;
  font-size: 1.2rem;
  border-bottom: 2px solid $gray;
  color: $white;
  padding: 7px 0;
  background: transparent;
  font-weight: 600;
  opacity: 0.4;
  outline: none !important;
}

.form__field3 {
  border: 0;
  font-size: 1.2rem;
  padding: 7px 0;
  font-weight: 700;
}

.contactbtn {
  background-color: black;
  color: white;
  border-radius: 30px;
  padding: 10px;
  font-size: 1.2rem;
}

.form__groupalig {
  display: flex;
  flex-direction: column;
}

.input-with-icon {
  display: flex;
  align-items: center;
  position: relative;
}

.iconstyle2 {
  position: absolute;
  right: 265px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 22px;
}

//component1

@media screen and (min-width: 850px) {
  .backgroundImage {
    background-image: url('../images/Frame4.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    min-height: 400px;
    background-size: cover;
    position: relative;
  }

  .contactoverlay {
    position: absolute;
    top: 56%;
    left: 62%;
    color: #fff;
    font-size: 1.2rem;
    opacity: 1;
    color: white
  }
}

.contactt1 {
  font-size: 40px;
  margin-left: -4px;
}

.c2fontweight66 {
  font-weight: 600;
}

.foriconheadingsfontweight {
  font-size: 18px;
  font-weight: 700;
}

.contactt2 {
  font-size: 17px;
  margin-top: -8px;
}

//component2
.textbold {
  font-weight: 600;
}

.addrescol1 {
  font-size: 14px;
}

@media screen and (max-width: 1024px) {
  .addrescol1 {
    font-size: 15px;
  }
}

@media screen and (min-width: 750px) and (max-width: 850px) {
  .addrescol1 {
    font-size: 11px;
  }
}

// messageform
.messagegroup {
  position: relative;
}

.messagefield,
.messagelabel {
  width: 65%;
  display: block;
  box-sizing: border-box;
}

.messagefield {
  border: 0;
  font-size: 1.2rem;
  border-bottom: 2px solid $gray;
  color: $white;
  padding: 7px 0;
  background: transparent;
  font-weight: 600;
  opacity: 0.4;
  outline: none !important;
  margin-top: -15px;
}

.messagelabel {
  font-size: 0.9rem;
  font-weight: 500;
  color: rgb(152, 152, 152);
}

//formresponsive
@media screen and (min-width: 1200px)and (max-width: 1400px) {
  .iconstyle2 {
    right: 230px;
  }
}

@media screen and (min-width: 1020px)and (max-width: 1200px) {
  .iconstyle2 {
    right: 190px;
  }
}

@media screen and (min-width: 750px)and (max-width: 850px) {
  .iconstyle2 {
    right: 0px;
  }

  .form__field2 {
    width: 100%;
  }

  .messagefield {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .form__field2 {
    width: 100%;
  }

  .messagefield {
    width: 100%;
  }

  .iconstyle2 {
    right: 0px;
  }
}

.contact_mainPage {
  overflow: hidden;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
}

.c_2 {
  height: 100vh;
}

.c2_heading {
  margin-top: 70px;
  font-size: 40px;
}

.con2_form {
  margin-top: 80px;
}
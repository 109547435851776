// product 1
.products11 {
    height: 100vh;
    min-height: 400px;
    background-size: cover;
}

.products12 {
    padding-top: 130px;
    text-align: center;
}

.products13 {
    color: white;
    font-size: 24px;
}

.products14 {
    font-size: 3rem;
    color: white;
    margin-top: 30px;
}

.products15 {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 200px;
}

.products17 {
    margin: 0 12rem;
}

// product 2
.products21 {
    font-size: 1.7rem;
    font-weight: 700;
}

.products22 {
    font-size: 1.7rem;
    font-weight: 600;
}

.products23 {
    font-size: 1.2rem;
    font-weight: 400;
}

// products 3
.products31 {
    width: 100%;
}

// products 4
.products41 {
    width: 80% !important;
}

.products42 {
    font-size: 3vw;
    font-weight: 600;
}

// products 8
.products81 {
    background: #666d75;
    color: #fff;
    padding: 60px 0 140px;
    height: 100vh;
}

.key-details-table .tr {
    align-items: center;
    border-bottom: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    width: 100%
}

.key-details-table .tr:first-child {
    padding: 20px 0
}

@media (max-width: 767px) {
    .key-details-table .tr:first-child {
        padding: 25px 0
    }

    .key-details-table .tr .logo {
        width: 108px
    }
}

.hidden-xs {
    width: 52%;
    height: 79%;
    margin-left: 100px;
    margin-top: 12px;
}

@media screen and (width >=990px) {
    .product4img {
        width: 140%;
    }

    .product5img {
        width: 140%;
        margin-left: -200px;
    }
}

.first-image871 {
    width: 57%;
    height: 60vh !important;
}

// product 6
.carousel-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.indicator {
    width: 30px;
    height: 30px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.first-image {
    width: 64.4%;
    height: 100%;
    position: absolute;
}

.second-image {
    width: 35%;
    height: 49.3%;
    position: absolute;
    right: 0;
}

.third-image {
    width: 35%;
    height: 49.3%;
    position: absolute;
    right: 0;
    margin-top: 24.7%;
}

.pp6_t1 {
    font-size: 32px;
}

.pp6_t2 {
    font-size: 15px;
}

.maindivpp1 {
    width: 90%;
    float: right;
    margin-top: 24%;
}

.maindivpp2 {
    width: 90%;
    margin-left: 5%;
    margin-top: 24%;
}

.maindivpp3 {
    width: 90%;
    margin-top: 24%;
}

.forppwidth {
    height: 140px;
}

.right-text4,
.right-text5,
.right-text6 {
    width: 38%;
    float: right;
    font-size: 18px;
    margin-top: 105px;
    margin-right: 25px;
}

.forppwidth-landing56 {
    height: 40px;
}

.products16 {
    margin-top: -40px;
}

.products17 {
    margin-top: -40px;
}

.products18 {
    margin-top: -40px;
}

.products_mainfw {
    overflow: hidden;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
}


@media screen and (1200px <=width <=1400px) {

    // c1
    .products15 {
        margin-top: 18%;
    }


    .right-text4,
    .right-text5,
    .right-text6 {
        font-size: 20px;
        margin-top: 14%;
    }
}


@media screen and (width >=1400px) {
    .products15 {
        margin-top: 20%;
    }

    .right-text4,
    .right-text5,
    .right-text6 {
        font-size: 20px;
        margin-top: 16%;
    }
}
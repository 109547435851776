.logoimg {
  width: 100%;
  height: 38px !important;
  left: 0 !important;
}

.custom-dropdown .dropdown-toggle::after {
  display: none;
}

.navcontact {
  margin: 0 50px;
}

.forproductsize {
  width: 40%;
  margin-top: 20px;
}

.hover-opacity {
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;
}

.hover-opacity:hover {
  opacity: 1;
}

.loginbtn {
  display: inline-block;
  padding: 0px 15px 1px 15px;
  background-color: orange;
  border-radius: 25px 25px 25px 25px;
  border: none;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  font-size: 20px;
  font-family: "Gotham", sans-serif;
  margin-right: 30px;
  margin-left: 30px;
}

.heafaf {
  display: none;
}

.Registerbtn {
  display: inline-block;
  padding: 0px 15px 1px 15px;
  background-color: orange;
  border-radius: 25px 25px 25px 25px;
  border: none;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  font-size: 20px;
  font-family: "Gotham", sans-serif;
}

.gbdgf {
  margin-right: 30px !important;
  font-size: 22px;
}


// for reserve button in product 2
.arrow-button43 {
  display: flex;
  color: #000000;
  background-color: #f1f1f1;
  padding: 6px 16px;
  border-radius: 0px;
  transition: all .3s ease;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  border: none;
}

.arrow-button43>.arrow {
  width: 6px;
  height: 6px;
  border-right: 2px solid #000000;
  border-bottom: 2px solid #000000;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 0px;
  margin-left: 10px;
  margin-top: 1px;
  transition: all .3s ease;
}

.arrow-button43>.arrow::before {
  display: block;
  background-color: currentColor;
  width: 3px;
  transform-origin: bottom right;
  height: 2px;
  position: absolute;
  opacity: 0;
  bottom: calc(-2px / 2);
  transform: rotate(45deg);
  transition: all .3s ease;
  content: "";
  right: 0;
}

.arrow-button43:hover>.arrow {
  transform: rotate(-45deg) translate(4px, 4px);
  border-color: text-hover-color;
}

.arrow-button43:hover>.arrow::before {
  opacity: 1;
  width: 8px;
}

.arrow-button43:hover {
  background-color: #929292;
  color: #000000;
}

.explore_btn {
  font-size: 17px;
  margin-top: 10px;
  opacity: 0.5;
}

.explore_btn:hover {
  opacity: 1;
}

.logoimg {
  width: 100%;
  height: 30px;
}

.custom-dropdown .dropdown-toggle::after {
  display: none;
}

.navcontact {
  margin: 0 50px;
}

.forproductsize {
  width: 40%;
  margin-top: 20px;
}

.hover-opacity {
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;
}

.hover-opacity:hover {
  opacity: 1;
}

.loginbtn {
  display: inline-block;
  padding: 0px 15px 1px 15px;
  background-color: orange;
  border-radius: 25px 25px 25px 25px;
  border: none;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  font-size: 20px;
  font-family: "Gotham", sans-serif;
  margin-right: 30px;
  margin-left: 30px;
}

.heafaf {
  display: none;
}

.Registerbtn {
  display: inline-block;
  padding: 0px 15px 1px 15px;
  background-color: orange;
  border-radius: 25px 25px 25px 25px;
  border: none;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  font-size: 20px;
  font-family: "Gotham", sans-serif;
}

.gbdgf {
  margin-right: 30px !important;
  font-size: 22px;
}


// for reserve button in product 2
.arrow-button43 {
  display: flex;
  color: #000000;
  background-color: #f1f1f1;
  padding: 6px 16px;
  border-radius: 0px;
  transition: all .3s ease;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  border: none;
}

.arrow-button43>.arrow {
  width: 6px;
  height: 6px;
  border-right: 2px solid #000000;
  border-bottom: 2px solid #000000;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 0px;
  margin-left: 10px;
  margin-top: 1px;
  transition: all .3s ease;
}

.arrow-button43>.arrow::before {
  display: block;
  background-color: currentColor;
  width: 3px;
  transform-origin: bottom right;
  height: 2px;
  position: absolute;
  opacity: 0;
  bottom: calc(-2px / 2);
  transform: rotate(45deg);
  transition: all .3s ease;
  content: "";
  right: 0;
}

.arrow-button43:hover>.arrow {
  transform: rotate(-45deg) translate(4px, 4px);
  border-color: text-hover-color;
}

.arrow-button43:hover>.arrow::before {
  opacity: 1;
  width: 8px;
}

.arrow-button43:hover {
  background-color: #929292;
  color: #000000;
}

.explore_btn {
  margin-top: 10px;
  font-weight: 500;
  opacity: 0.5;
}

.explore_btn:hover {
  opacity: 1;
}

//forpop up
$primary: rgb(32, 32, 32);
$secondary:#00B3F2;
$white: #565656;
$gray: #969696;

.form__group {
  position: relative;
  width: 100%;
}

.form__field {
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: none !important;
  color: $white;
  font-size: 1.1rem;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown~.form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $gray;
}

//  for label (Email)
.form__field:focus {
  ~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $primary;
    font-weight: 700;
  }

  padding-bottom: 6px;
  font-weight: 500;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary, $secondary);
  border-image-slice: 1;
}

.form__field {

  &:required,
  &:invalid {
    box-shadow: none;
  }
}

//popup
@media screen and (width >=990px) {
  .nav-ul {
    margin-left: -120px;
  }
}

@media screen and (width >=1200px) {
  .nav-ul {
    margin-left: -120px;
  }
}

@media screen and (width >=1400px) {
  .nav-ul {
    margin-left: -115px;
  }
}

.nav__links {
  font-size: 19px !important;
}

// new popup
@media screen and (width >=990px) {
  .fsfsd {
    width: 35% !important;
    right: 0;
  }

  .inputcss {
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none !important;
  }

  .inputcss:focus {
    box-shadow: none !important;
  }

  .googlespan__ {
    padding: 10px;
  }

  .googicon {
    margin-right: 5px;
    margin-top: -6px;
  }

  .gootop {
    margin-top: -15px;
    margin-bottom: 9px;
  }

  .fullnamecss5 {
    margin-top: -20px;
  }

  .emailcss98 {
    margin-top: -20px;
  }
}

@media screen and (width >=990px) {
  .textkawidth {
    width: 130px;
  }

  .googlespan__1 {
    margin-left: -37px;
  }

  .fullnamecss5 {
    margin-top: -20px;
  }

  .emailcss98 {
    margin-top: -20px;
  }
}
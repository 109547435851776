// hiding scroll
.hiding_scroll {
  background-color: #eee;
  overflow-y: scroll;
}

.hiding_scroll::-webkit-scrollbar {
  display: none;
}

.hiding_scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.custom-input:hover,
.custom-input:focus {
  border-color: #f39c12 !important;
  box-shadow: 0 0 10px orange !important;
  outline: #f39c12 !important;
}

.custom-button {
  border-color: #ff9d00 !important;
  box-shadow: 0 0 10px orange !important;
}

.custom-button:hover,
.custom-button:focus {
  background-color: #ff9d00 !important;
}

.header {
  height: 100vh;
  min-height: 400px;
  background-size: cover;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }

  .text-overlay {
    position: absolute;
    top: 56%;
    left: 50%;
    margin-left: -120px;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2rem;
    opacity: 1;
  }
}

.p1t1 {
  font-size: 45px;
}

.p1t2 {
  font-size: 18px;
}

.p1btn {
  display: inline-block;
  padding: 0px 15px 1px 15px;
  background-color: orange;
  border-radius: 25px 25px 25px 25px;
  border: none;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
}

.p2t1 {
  font-size: 20px;
  font-weight: 600;
  width: 75%;
  text-align: center;
  margin: 0 auto;
}

.p2t2 {
  font-size: 13px;
  width: 65%;
  margin: 5px auto;
  text-align: center;
}

.p2btn {
  display: inline-block;
  padding: 0px 15px 1.5px 15px;
  background-color: white;
  border: 1px solid orange;
  border-radius: 25px;
  color: orange;
  font-size: 15px;
  margin: 15px auto;
  text-align: center;
  display: flex;
}

.bgcolorforbtnhover:hover {
  background-color: orange;
  color: white;
}

.p3t1 {
  margin-top: -20px;
  margin-bottom: 35px;
  font-size: 35px;
  display: flex;
  justify-content: center;
}

.empdiv {
  height: 120px;
}

.p3_img1 {
  width: 50%;
  height: 250px;
  margin-left: 240px;
}

.p3_img2 {
  width: 120px;
  height: 70px;
  margin-left: 190px;
  margin-top: -500px;
}

.p3card {
  width: 27rem;
  position: absolute;
  margin-left: 575px;
  margin-top: -263px;
}

.p3_text1 {
  font-size: 30px;
}

.p3_text2 {
  font-size: 12px;
  font-weight: 300;
  width: 380px;
}

.p3btn1 {
  display: inline-block;
  padding: 0px 15px 1.5px 15px;
  background-color: transparent;
  border: 1px solid orange;
  border-radius: 25px;
  color: orange;
  font-size: 17px;
  margin: 0px 10px;
}

.headerp4 {
  height: 100vh;
  min-height: 400px;
  background-size: cover;
  position: relative;

  .p4_text1 {
    position: absolute;
    top: 25%;
    left: 62.5%;
    margin-left: -120px;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 36px;
    opacity: 1;
  }

  .p4cols {
    position: absolute;
    margin-top: -115px;
    margin-left: 80px;
    font-size: 17px;
  }
}

.p6t1 {
  font-size: 18px;
  font-weight: 600;
  color: rgb(176, 176, 176);
}

.p6t2 {
  font-size: 12px;
  font-weight: 500;
  color: rgb(176, 176, 176);
}

.p6t3 {
  font-size: 18px;
  font-weight: 600;
  color: rgb(176, 176, 176);
}

.p6t4 {
  font-size: 12px;
  font-weight: 500;
  color: rgb(176, 176, 176);
}

.p6t5 {
  font-size: 18px;
  font-weight: 600;
  color: rgb(176, 176, 176);
}

.p6t6 {
  font-size: 12px;
  font-weight: 500;
  color: rgb(176, 176, 176);
}

.landing6_heightwidthes {
  height: 120px !important;
}

.p7t1 {
  font-weight: 600;
  font-size: 25px;
  display: flex;
  justify-content: center;
}

.p7t2 {
  font-weight: 300;
  font-size: 14px;
  display: flex;
  justify-content: center;
  margin-top: -18px;
}

.p7btn {
  display: inline-block;
  padding: 0px 15px 1.5px 15px;
  background-color: white;
  border: 1px solid orange;
  border-radius: 25px;
  color: orange;
  font-size: 15px;
  margin: 15px auto;
  text-align: center;
  display: flex;
}

.p8t1 {
  font-size: 30px;
  font-weight: 500;
  width: 75%;
  text-align: center;
  margin: 20px auto;
}

.p8t2 {
  width: 43%;
  text-align: center;
  margin: 0 auto;
}

.Landingpage8_main-div {
  margin-top: 140px;
  margin-bottom: 100px;
}

.Landingpage7_main-div {
  height: 100vh;
}

.first-image87 {
  width: 57%;
  height: 66vh !important;
}

.right-text1,
.right-text2,
.right-text3 {
  width: 38%;
  float: right;
  font-size: 17px;
  margin-top: 125px;
  margin-right: 25px;
}

.forppwidth-landing {
  height: 30px;
}

.hrline54 {
  margin-bottom: 30px;
  margin-top: 10px;
}

.email__input3 {
  width: 240px;
  background-color: #f5f5f5;
  color: #242424;
  padding: 0px 10px;
  padding-right: 40px;
  min-height: 32.5px;
  border-radius: 3px;
  outline: gray;
  border: 1px solid rgb(229, 229, 229);
  line-height: 1.15;
  margin: 10px 2px;
  font-size: 13px;
}

.email__input3:focus {
  border-bottom: 2px solid #5b5fc7;
  border-radius: 4px 4px 2px 2px;
}

.email__input3:hover {
  outline: 1px solid lightgrey;
}

.p8btn {
  display: inline-block;
  padding: 5px 16px;
  background-color: orange;
  border-radius: 25px 25px 25px 25px;
  border: none;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  font-size: 15px;
  margin-left: 5px;
}

@media screen and (width >=1200px) {
  .p3_img1 {
    width: 50%;
    height: 280px;
    margin-left: 240px;
  }

  .p3_img2 {
    width: 120px;
    height: 70px;
    margin-left: -610px;
    margin-top: -250px;
  }

  .landing_p2contnet {
    margin-left: -10px !important;
  }
}

@media screen and (width >=1400px) {
  .p3_img1 {
    width: 50%;
    height: 290px;
    margin-left: 200px;
  }

  .p3_img2 {
    width: 120px;
    height: 70px;
    margin-left: -640px;
    margin-top: -270px;
  }

  .landing_p2contnet {
    margin-left: 100px !important;
  }
}

.firstcss {
  margin-top: -52px;
}

.labels3243 {
  font-weight: 600;
}

.slide-enter {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

.slide-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 300ms, opacity 300ms;
}

.book_icon {
  float: right;
  margin-right: 10px;
  margin-top: -27px;
}

.landing_manipage {
  overflow: hidden;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
}







@media screen and (1200px <=width <=1400px) {

  // c1
  .p1t1 {
    font-size: 57px;
    width: 850px;
    margin-left: 100px;
  }

  .p1t2 {
    font-size: 23px;
    margin-left: 100px;
  }

  .p1btn {
    font-size: 23px;
    margin-left: 100px;
  }

  // c2
  .pc-2 {
    margin-top: 700px !important;
  }

  // c3
  .right-text1,
  .right-text2,
  .right-text3 {
    font-size: 20px;
    margin-top: 14%;
  }
}

@media screen and (width >=1400px) {

  // c1
  .p1t1 {
    font-size: 60px;
    width: 950px;
    margin-left: 100px;
  }

  .p1t2 {
    font-size: 25px;
    margin-left: 100px;
  }

  .p1btn {
    font-size: 25px;
    margin-left: 100px;
  }

  .pc-2 {
    margin-top: 750px !important;
  }
    // c3
    .right-text1,
    .right-text2,
    .right-text3 {
      font-size: 20px;
      margin-top: 16%;
    }
}
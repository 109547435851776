.aboutusimage123 {
    height: 100vh;
}

.aboutusimagetext123 {
    position: absolute;
    font-size: 28px;
    width: 68%;
    font-weight: 400;
}

.forinputstyles {
    outline: none !important;
    width: 40%;
    padding: 10px;
    color: black
}

.forsubmitstyle {
    outline: none !important;
    padding: 10px;
    background-color: transparent;
    color: #969696
}

.forwidth50 {
    width: 50% !important;
}

.forwidth40 {
    width: 40% !important;
}

.row2margintop {
    margin-top: 10px;
}

.forredcolor55 {
    color: red;
}

.another_class354 {
    text-align: center;
    margin: 20px auto;
    padding: 20px;
}

.aboutgapfornavtopage {
    height: 100vh;
}

@media screen and (min-width:600px) {
    .forimagesflex {
        display: flex;
    }

    .forimh122 {
        width: 60%;
        padding: 10px;
        margin-left: 5%
    }

    .forim888 {
        width: 35%;
        padding: 10px 0px;
    }

    .formbelowmargin {
        margin-bottom: 120px;
    }

    .forabt7margintop {
        margin-top: 100px;
    }

    .abt1leftsidesize {
        font-weight: 600;
        font-size: 30px;
    }

    .abt1rightsidesize {
        font-weight: 400;
        font-size: 30px;
    }
}

// for about mobile view
@media screen and (max-width:600px) {
    .forimh122 {
        width: 100%;
        margin-bottom: 8px;
    }

    .forim888 {
        width: 100%;
    }

    .aboutgapfornavtopage {
        margin-top: 80px;
    }

    .forwidth50 {
        width: 100% !important
    }

    .forwidth40 {
        width: 100% !important
    }

    .formbelowmargin {
        margin-bottom: 90px;
    }

    .forabt7margintop {
        margin-top: 100px;
    }

    .abt1rightsidesize {
        font-weight: 400;
        font-size: 18px;
    }

    .abt1leftsidesize {
        font-weight: 600;
        font-size: 30px;
    }
}

.dsfjasdf {
    display: flex;
}

.col-40 {
    width: 40%;
    text-align: center;
    background-color: #f0f0f0;
}

.col-60 {
    width: 60%;
}

.bg-image575 {
    background-image: url('../images/Frame2.png');
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.centered-text {
    color: #000000;
    font-size: 33px;
    text-align: center;
    margin-top: 40px;
    font-weight: 600;
}

.my_abouttext {
    color: #000000;
    font-size: 33px;
    margin-top: 58%;
    font-weight: 600;
}

.about_mainPage {
    overflow: hidden;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
}

.about_3 {
    height: 100vh;
}

.a3_head {
    margin-top: 40%;
}

.a5_head {
    margin-top: 45%;
}

.a_5 {
    height: 100vh;
}

.a_6 {
    height: 100vh;
}

.a6_head {
    margin-top: 45%;
}
.textregular {
  font-family: 'Roboto', sans-serif;
}

.footer43 {
  background-color: #000000;
  margin-bottom: -16px;
  width: 100%;
}

.footer_row {
  font-size: 9px;
  width: 65%;
  margin: 0px auto;
  padding-top: 40px;
}

.footer_row2 {
  color: white;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}

.footer_row3 {
  color: white;
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
}

.footer_row4 {
  color: white;
  font-size: 12px;
  text-align: center;
  margin-top: 12px;
}

.footer_row5 {
  color: white;
  font-size: 9px;
  margin-top: 12px;
}

.fc1 {
  width: 38px;
  height: 38px !important;
  background-color: orange !important;
  border-radius: 0 !important;
  border-radius: 2px !important;
  padding-top: 1.5px;
  padding: 6px;
}

.custom-margin1 {
  margin-right: 5px;
}

.forfootericons99 {
  display: flex;
  list-style: none;
}

.forul-alignments77 {
  list-style: none;
}

.forfooterli {
  font-size: 12px;
}

@media screen and (min-width:"990px") {
  .forul-alignments77 {
    display: flex;
    justify-content: center;
  }

  .forfooterli {
    margin-right: 40px;
  }

  .forfootericons99 {
    margin-left: -30px;
  }
}

@media screen and (max-width:"700px") {
  .forul-alignments77 {
    justify-content: center;
    text-align: center;
  }

  .forfooterli {
    margin: 13px 0px
  }
}